// Add global to window for tests
(function(win) {
    win.global = win;
})(window);

/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';

/**
 * Required for Angular
 */
import 'zone.js';

/**
 * APPLICATION IMPORTS
 */
import 'intl';
import 'intl/locale-data/jsonp/en.js';
